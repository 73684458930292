<template>
	<div>
		<CRow class="mb-3">
			<CCol class="text-right">
				<CButton
					class="btn-clear-cache mr-3"
					color="tertiary"
					data-test-id="btn-clear-cache"
					@click="handleClearCache"
				>
					Clear cache
				</CButton>
				<CButton
					class="btn-re-sync mr-3"
					color="tertiary"
					data-test-id="btn-re-sync"
					@click="handleReSyncAllProductClick"
				>
					Re-sync product
				</CButton>
				<router-link
					:to="{ name: 'MarketingCampaignCreate' }"
					class="btn btn-secondary"
				>
					<CIcon name="cil-plus" class="mr-2" />
					Create marketing campaign
				</router-link>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-id="search-input"
						placeholder="Search by marketing campaign name or ID"
					>
						<template #prepend-content>
							<CIcon
								name="cil-magnifying-glass"
								data-id="search-button"
							/>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.is_active, MARKETING_CAMPAIGN_STATUS_OPTIONS)"
									:options="MARKETING_CAMPAIGN_STATUS_OPTIONS"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Marketing campaign status"
									@input="handleFilterChange('is_active', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Period date"
									placeholder="All periods"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.period_status, MARKETING_CAMPAIGN_PERIOD_OPTIONS)"
									:options="MARKETING_CAMPAIGN_PERIOD_OPTIONS"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Period status"
									@input="handleFilterChange('period_status', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.type, MARKETING_CAMPAIGN_PRODUCT_TYPE_OPTIONS)"
									:options="MARKETING_CAMPAIGN_PRODUCT_TYPE_OPTIONS"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Add product by"
									@input="handleFilterChange('type', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="{ name: 'All type', value: null }"
									:options="[]"
									disabled
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Promotion period"
									@input="handleFilterChange('N/A', $event)"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.sync_status, MARKETING_CAMPAIGN_SYNC_OPTIONS)"
									:options="MARKETING_CAMPAIGN_SYNC_OPTIONS"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Sync status"
									@input="handleFilterChange('sync_status', $event)"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="mb-4">
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="lists found"
					@onReset="handleResetFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="list.isLoading"
					:pagination="{
						pages: pagination.lastPage,
						activePage: pagination.currentPage
					}"
					:fields="MARKETING_CAMPAIGN_TABLE_FIELDS"
					:items="dataTable"
					clickable-rows
					striped
					link-to="MarketingCampaignEdit"
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "marketing campaign" }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge
							class="badge-status"
							:color="item"
						>
							{{ item | capitalize }}
						</CBadge>
					</template>
					<template #period="{item}">
						<CBadge
							class="badge-status"
							:color="MARKETING_CAMPAIGN_PERIOD_COLOR[item]"
						>
							{{ item | capitalize }}
						</CBadge>
					</template>
					<template #syncStatus="{item}">
						<MarketingCampaignSyncStatus
							:id="item.id"
							:status="item.status"
							@onReSync="handleReSyncProduct"
						/>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<BaseModalLoading ref="modal-loading" modal-title="Syncing" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseSearchFormFooter from '@/components/BaseSearchFormFooter.vue';
import MarketingCampaignSyncStatus from '@/components/MarketingCampaignSyncStatus.vue';
import {
	MARKETING_CAMPAIGN_PERIOD_COLOR,
	MARKETING_CAMPAIGN_PERIOD_OPTIONS,
	MARKETING_CAMPAIGN_PRODUCT_TYPE_NAME,
	MARKETING_CAMPAIGN_PRODUCT_TYPE_OPTIONS,
	MARKETING_CAMPAIGN_STATUS,
	MARKETING_CAMPAIGN_STATUS_OPTIONS,
	MARKETING_CAMPAIGN_SYNC_OPTIONS,
} from '../enums/marketings';
import {
	capitalize,
	cleanObject,
	cloneDeep,
	convertDateRangeToUTC,
	datetimeFormat,
	getBooleanQueryParameter,
	hasSearchFilter,
	numberFormat,
	randomSearchString,
} from '../assets/js/helpers';
import { reSyncAllCampaignAPI, reSyncCampaignAPI, clearCacheAPI } from '../services/api/marketingCampaigns.api';

export default {
	name: 'MarketingList',
	filters: {
		capitalize,
	},
	components: {
		BaseSearchFormFooter,
		MarketingCampaignSyncStatus,
	},
	data() {
		return {
			MARKETING_CAMPAIGN_STATUS_OPTIONS,
			MARKETING_CAMPAIGN_PERIOD_OPTIONS,
			MARKETING_CAMPAIGN_PRODUCT_TYPE_OPTIONS,
			MARKETING_CAMPAIGN_PERIOD_COLOR,
			MARKETING_CAMPAIGN_SYNC_OPTIONS,
			isShowFilter: false,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				is_active: getBooleanQueryParameter(this.$route.query.is_active),
				period_status: this.$route.query.period_status || null,
				type: this.$route.query.type || null,
				sync_status: this.$route.query.sync_status || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('marketingCampaigns', {
			list: 'list',
		}),
		MARKETING_CAMPAIGN_TABLE_FIELDS() {
			return [
				{ key: 'id', label: 'ID' },
				{ key: 'name', label: 'Marketing campaign name', class: 'th-mw-200' },
				{ key: 'status', label: 'Status' },
				{ key: 'type', label: 'Add product by' },
				{ key: 'totalSKU', label: 'Total SKUs' },
				{ key: 'startDate', label: 'Start date', class: 'th-mw-150' },
				{ key: 'endDate', label: 'End date', class: 'th-mw-150' },
				{ key: 'period', label: 'Period' },
				{ key: 'isVisible', label: 'Store front visibility' },
				{ key: 'syncStatus', label: 'Sync status', class: 'th-mw-150' },
				{ key: 'syncedAt', label: 'Last synced', class: 'th-mw-150' },
			];
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		dateRange() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},
		dataTable() {
			return this.list.data.map((marketingCampaign) => {
				return {
					id: marketingCampaign.id,
					name: marketingCampaign.nameTh,
					status: marketingCampaign.isActive
						? MARKETING_CAMPAIGN_STATUS.ACTIVE
						: MARKETING_CAMPAIGN_STATUS.INACTIVE,
					type: MARKETING_CAMPAIGN_PRODUCT_TYPE_NAME[marketingCampaign.type] || '-',
					totalSKU: numberFormat(marketingCampaign.totalSKU),
					period: marketingCampaign.periodStatus,
					startDate: datetimeFormat(marketingCampaign.startAt),
					endDate: datetimeFormat(marketingCampaign.endAt) || 'No fixed time',
					isVisible: marketingCampaign.isVisible
						? 'Show'
						: 'Hide',
					syncStatus: {
						id: marketingCampaign.id,
						status: marketingCampaign.syncStatus,
					},
					syncedAt: marketingCampaign.syncedAt,
				};
			});
		},
		pagination() {
			return this.list.meta;
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getMarketingCampaignList(this.queryParams);
	},
	methods: {
		...mapActions({
			getMarketingCampaignList: 'marketingCampaigns/getMarketingCampaignList',
			showToast: 'toast/showToast',
		}),
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleResetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				start_at: null,
				end_at: null,
				is_active: null,
				period_status: null,
				type: null,
				sync_status: null,
				r: null,
			};

			this.updateUrlParams();
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleFilterChange(key, event) {
			this.queryParams.page = null;
			this.queryParams[key] = event.value;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		async handleReSyncAllProductClick() {
			this.$refs['modal-loading'].open();

			try {
				await reSyncAllCampaignAPI();
				await this.getMarketingCampaignList(this.queryParams);

				this.showToast({
					content: 'All campaingns are syncing. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});
			} catch {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Unable to re-sync',
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
		async handleClearCache() {
			this.$refs['modal-loading'].open();

			try {
				await clearCacheAPI();
				await this.getMarketingCampaignList(this.queryParams);

				this.showToast({
					content: 'All campaingns are cleared cache. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});
			} catch {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Unable to clear cache',
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
		async handleReSyncProduct(id) {
			this.$refs['modal-loading'].open();

			try {
				await reSyncCampaignAPI(id);
				await this.getMarketingCampaignList(this.queryParams);

				this.showToast({
					content: 'The campaign is syncing. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});
			} catch {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Unable to re-sync',
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.badge-status.badge {
		&.badge-secondary {
			background-color: $color-gray-400;
			color: $color-black-70;
		}
	}
</style>
