<template>
	<span class="mkt-status">
		<template v-if="status === MARKETING_CAMPAIGN_SYNC_STATUS.SYNC_ERROR">
			Sync error <a @click.prevent="handleIconSyncClick"><CIcon class="icon-sync" name="cil-sync" /></a>
		</template>
		<template v-else-if="status === MARKETING_CAMPAIGN_SYNC_STATUS.SYNCING">
			Syncing...
		</template>
		<template v-else-if="status === MARKETING_CAMPAIGN_SYNC_STATUS.COMPLETED">
			Completed
		</template>
		<template v-else>
			-
		</template>
	</span>
</template>

<script>
import { MARKETING_CAMPAIGN_SYNC_STATUS } from '../enums/marketings';

export default {
	name: 'MarketingCampaignSyncStatus',

	props: {
		status: {
			type: String,
			default: null,
		},
		id: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			MARKETING_CAMPAIGN_SYNC_STATUS,
		};
	},
	methods: {
		handleIconSyncClick() {
			this.$emit('onReSync', this.id);
		},
	},
};
</script>

<style lang="scss" scoped>
	.icon-sync {
		margin-left: rem(4);
		color: $color-hypertext;
	}
</style>
